<template>
    <div
        class="ui-dropdown-list"
        :class="{
            'ui-dropdown-list-label-top': labelPosition == 'top',
            'ui-dropdown-list-label-before': labelPosition == 'before',
        }"
    >
        <div v-if="label" class="w-full field-label">
            <span>{{ label }}</span> <span v-if="required">*</span>
        </div>

        <ComboboxRoot
            :id="id"
            :name="name"
            v-model="valueRef"
            class="combobox-root"
            :class="{ 'combobox-root--error': error }"
            :multiple="multiple"
            v-model:open="opened"
            :default-open="false"
        >
            <ComboboxAnchor class="combobox-anchor">
                <TagsInputRoot v-if="value != null" v-model="valueRefComp" class="tags-input-root">
                    <TagsInputItem v-for="item in valueRefComp" :key="item" :value="item" class="tags-input-item">
                        <TagsInputItemText class="tags-input-item-text" />
                        <TagsInputItemDelete>
                            <img src="/assets/icons/check.svg" class="icon-small" />
                        </TagsInputItemDelete>
                    </TagsInputItem>
                </TagsInputRoot>
                <div v-else class="combobox-placeholder">{{ placeholder }}</div>

                <ComboboxTrigger class="combobox-trigger">
                    <img src="/assets/icons/chevron-down-filled-triangle.svg" class="icon-normal" />
                </ComboboxTrigger>
            </ComboboxAnchor>

            <ComboboxContent class="combobox-content">
                <ComboboxViewport class="combobox-viewport">
                    <ComboboxEmpty class="combobox-empty" />
                    <template v-for="(option, index) in options" :key="index">
                        <ComboboxItem v-if="option.label != ''" :value="option.value ?? option.label" class="combobox-item"
                            >{{ option.label }}
                            <ComboboxItemIndicator class="combobox-item-indicator">
                                <img src="/assets/icons/check.svg" class="icon-small" />
                            </ComboboxItemIndicator>
                        </ComboboxItem>
                    </template>
                </ComboboxViewport>

                <ComboboxArrow />
            </ComboboxContent>
        </ComboboxRoot>
        <div v-if="error" class="ui-dropdown-list--error-message">{{ errorMessage }}</div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useVModel } from '@vueuse/core';

import { TagsInputItem, TagsInputItemDelete, TagsInputItemText, TagsInputRoot } from 'radix-vue';
import {
    ComboboxAnchor,
    ComboboxArrow,
    ComboboxContent,
    ComboboxEmpty,
    ComboboxItem,
    ComboboxItemIndicator,
    ComboboxRoot,
    ComboboxTrigger,
    ComboboxViewport,
} from 'radix-vue';
import { isString } from 'lodash';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    labelPosition: {
        type: String,
        default: 'top',
    },
    placeholder: {
        type: String,
        default: '',
    },
    value: {
        type: [Array, String, null],
        required: true,
    },
    options: {
        type: Array,
        required: true,
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    error: {
        type: Boolean,
        default: false,
    },
    errorMessage: {
        type: String,
        default: '',
    },
});
const emit = defineEmits(['update:value']);

const valueRef = useVModel(props, 'value', emit);
const valueRefComp = computed({
    get: () => {
        if (valueRef.value == null) {
            return [];
        }
        let optionsSelected = valueRef.value;
        if (isString(valueRef.value)) {
            optionsSelected = [valueRef.value];
        }
        // if (props.multiple) {
        // return valueRef.value;
        // } else {
        // if (Array.isArray(valueRef.value)) {
        // return valueRef.value;
        // } else {
        // return [valueRef.value];
        // }
        // }
        const optionNamesSelected = optionsSelected.map((optionSelected) => {
            const propsOption = props.options.find((option) => {
                return (option.value ?? option.label) == optionSelected;
            });
            return propsOption ? propsOption.label : '';
        });
        return optionNamesSelected;
    },
    set: (optionNamesSelected) => {
        const optionsSelected = optionNamesSelected.map((optionNameSelected) => {
            const propsOption = props.options.find((option) => {
                return option.label == optionNameSelected;
            });
            return propsOption.value;
        });
        valueRef.value = optionsSelected;
    },
});

const opened = ref(false);
// const displayValue = computed(() => {
//     return (val) => {
//         if (val == null || val == undefined) {
//             return '';
//         }
//         return val.toString();
//     };
// });
watch(
    () => props.multiple,
    (newMultiple) => {
        if (newMultiple) {
            valueRef.value = [];
        } else {
            valueRef.value = null;
        }
    }
);

console.log('PROPS DROPDOWNLIST: ', props.options);
</script>

<style lang="postcss" scoped>
.ui-dropdown-list {
    @apply flex w-full mb-4;
}
.ui-dropdown-list-label-before {
    @apply flex-row;
}
.ui-dropdown-list-label-top {
    @apply flex-col;
}
.ui-dropdown-list--error-message {
    @apply mt-1 text-xs font-medium text-error-dark;
}

.tags-input-root {
    @apply flex gap-2 items-center p-1 rounded w-full h-9 flex-wrap bg-white;
}
.tags-input-item {
    @apply text-neutral-dark flex items-center justify-center gap-2 bg-neutral-white rounded p-1;
    @apply border-2 border-neutral-light-dark;
}
.tags-input-item-text {
    @apply text-[0.875em] pl-1;
}
.tags-input-item-delete {
    @apply p-0.5 rounded bg-transparent hover:bg-stone-900;

    & > img {
        filter: invert(1);
    }
}

.combobox-root {
    @apply relative;
}
.combobox-trigger {
    @apply w-6;
}
.combobox-anchor {
    @apply min-w-[160px] w-full h-12 inline-flex gap-2 items-center justify-between;
    @apply px-3 py-2;
    @apply border-solid border rounded border-neutral-normal-light;
    @apply leading-none  bg-white;
}
.combobox-root--error .combobox-anchor {
    @apply border-error-normal;
}
.combobox-placeholder {
    @apply text-neutral-normal;
}
.combobox-input {
    @apply !bg-transparent outline-none text-stone-900 h-full selection:bg-sky-500 placeholder-stone-100;
}
.combobox-trigger {
}
:deep(.combobox-content) {
    @apply absolute z-10 w-11/12 mt-0 min-w-[160px] bg-white overflow-hidden rounded text-[1em];
    box-shadow: 0px 12px 18px -4px #10182814;
    @apply will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade;
    @apply data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade;
    @apply border-2 border-neutral-light-dark;
    @apply mt-2;
}
.combobox-viewport {
    @apply p-[5px];
}
.combobox-empty {
    @apply text-[0.75em] font-medium text-center py-2;
}
.combobox-item {
    @apply leading-none text-stone-900 rounded-[3px] flex items-center text-[1em];
    @apply h-[25px] pr-[35px] pl-[25px] relative select-none;
    @apply data-[disabled]:text-stone-100 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-green-light-hover data-[highlighted]:text-neutral-darker;
}
.combobox-item-indicator {
    @apply absolute left-0 w-[25px] inline-flex items-center justify-center;
}
</style>
